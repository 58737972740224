.not-found {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('/assets/images/notfound.jpg');
  .background-overlay {
    position: fixed;
    width: 100%;
    height: 100%;

    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.5) 40%,
      rgba(0, 0, 0, 0.5) 70%,
      rgba(0, 0, 0, 0.8) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.5) 40%,
      rgba(0, 0, 0, 0.5) 70%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }
  .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding-top: 23px;
    .logo {
    }
  }
  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .error {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 1.86;
      letter-spacing: -0.2px;
      text-align: center;
      color: #ffffff;
    }
    .code {
      margin-bottom: 45px;
      font-size: 150px;
      font-weight: bold;
      line-height: 1;
      text-align: left;
      color: #ffffff;
    }
    .title {
      margin-bottom: 27px;
      font-size: 28px;
      font-weight: normal;
      line-height: 1.61;
      letter-spacing: -0.2px;
      text-align: center;
      color: #ffffff;
    }
    .description {
      margin-bottom: 30px;
      font-size: 14px;
      line-height: 1.86;
      letter-spacing: -0.2px;
      text-align: center;
      font-weight: 300;
      color: #ffffff;
    }
    .ant-btn {
      width: 200px;
      height: 40px;
    }
  }
}
