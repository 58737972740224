.embla {
  position: relative;
  overflow: hidden;
}

.embla__container {
  display: flex;
}

.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.embla__dot {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}

.embla__dots {
  z-index: 1;
  bottom: 1.6rem;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.embla__dot {
  width: 8px;
  height: 8px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-left: 8px;
}
.embla__dot:after {
  background: #ffffff;
  border-radius: 50%;
  width: 100%;
  height: 8px;
  content: '';
}
.embla__dot--selected:after {
  background: #ff3d8f;
}
