.class-invoice-table-title {
  color: var(--Neutral-70, #808387);

  /* Body/Body1 */
  font-family: 'Pretendard';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.class-invoice-table-description {
  color: var(--Neutral-90, #424242);
  text-align: right;
  /* Subtitle/Subtitle1 */
  font-family: 'Pretendard';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  white-space: pre-wrap;
}
