:root {
    --fc-small-font-size: .85em;
    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: hsla(0,0%,82%,.3);
    --fc-neutral-text-color: grey;
    --fc-border-color: #ddd;
    --fc-button-text-color: #fff;
    --fc-button-bg-color: #2c3e50;
    --fc-button-border-color: #2c3e50;
    --fc-button-hover-bg-color: #1e2b37;
    --fc-button-hover-border-color: #1a252f;
    --fc-button-active-bg-color: #1a252f;
    --fc-button-active-border-color: #151e27;
    --fc-event-bg-color: #3788d8;
    --fc-event-border-color: #3788d8;
    --fc-event-text-color: #fff;
    --fc-event-selected-overlay-color: rgba(0,0,0,.25);
    --fc-more-link-bg-color: #d0d0d0;
    --fc-more-link-text-color: inherit;
    --fc-event-resizer-thickness: 8px;
    --fc-event-resizer-dot-total-width: 8px;
    --fc-event-resizer-dot-border-width: 1px;
    --fc-non-business-color: hsla(0,0%,84%,.3);
    --fc-bg-event-color: #8fdf82;
    --fc-bg-event-opacity: 0.3;
    --fc-highlight-color: rgba(188,232,241,.3);
    --fc-today-bg-color: rgba(255,220,40,.15);
    --fc-now-indicator-color: red;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #858796;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.35rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn:hover {
  color: #858796;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #ff3d8f;
  border-color: #3f94f7;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2e59d9;
  border-color: #2653d4;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #2e59d9;
  border-color: #2653d4;
  box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #4e73df;
  border-color: #4e73df;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2653d4;
  border-color: #244ec9;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #858796;
  border-color: #858796;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #717384;
  border-color: #6b6d7d;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #717384;
  border-color: #6b6d7d;
  box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #858796;
  border-color: #858796;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6b6d7d;
  border-color: #656776;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #1cc88a;
  border-color: #1cc88a;
}

.btn-success:hover {
  color: #fff;
  background-color: #17a673;
  border-color: #169b6b;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #17a673;
  border-color: #169b6b;
  box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #1cc88a;
  border-color: #1cc88a;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #169b6b;
  border-color: #149063;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 208, 156, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #36b9cc;
  border-color: #36b9cc;
}

.btn-info:hover {
  color: #fff;
  background-color: #2c9faf;
  border-color: #2a96a5;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #2c9faf;
  border-color: #2a96a5;
  box-shadow: 0 0 0 0.2rem rgba(84, 196, 212, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #36b9cc;
  border-color: #36b9cc;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #2a96a5;
  border-color: #278c9b;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 196, 212, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #f6c23e;
  border-color: #f6c23e;
}

.btn-warning:hover {
  color: #fff;
  background-color: #f4b619;
  border-color: #f4b30d;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #fff;
  background-color: #f4b619;
  border-color: #f4b30d;
  box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #f6c23e;
  border-color: #f6c23e;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #f4b30d;
  border-color: #e9aa0b;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #e74a3b;
  border-color: #e74a3b;
}

.btn-danger:hover {
  color: #fff;
  background-color: #e02d1b;
  border-color: #d52a1a;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #e02d1b;
  border-color: #d52a1a;
  box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #e74a3b;
  border-color: #e74a3b;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #d52a1a;
  border-color: #ca2819;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
}

.btn-light {
  color: #3a3b45;
  background-color: #f8f9fc;
  border-color: #f8f9fc;
}

.btn-light:hover {
  color: #3a3b45;
  background-color: #dde2f1;
  border-color: #d4daed;
}

.btn-light:focus,
.btn-light.focus {
  color: #3a3b45;
  background-color: #dde2f1;
  border-color: #d4daed;
  box-shadow: 0 0 0 0.2rem rgba(220, 221, 225, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #3a3b45;
  background-color: #f8f9fc;
  border-color: #f8f9fc;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #3a3b45;
  background-color: #d4daed;
  border-color: #cbd3e9;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 221, 225, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #5a5c69;
  border-color: #5a5c69;
}

.btn-dark:hover {
  color: #fff;
  background-color: #484a54;
  border-color: #42444e;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #484a54;
  border-color: #42444e;
  box-shadow: 0 0 0 0.2rem rgba(115, 116, 128, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #5a5c69;
  border-color: #5a5c69;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #42444e;
  border-color: #3d3e47;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(115, 116, 128, 0.5);
}

.btn-outline-primary {
  color: #4e73df;
  border-color: #4e73df;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #4e73df;
  border-color: #4e73df;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #4e73df;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #4e73df;
  border-color: #4e73df;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.5);
}

.btn-outline-secondary {
  color: #858796;
  border-color: #858796;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #858796;
  border-color: #858796;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #858796;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #858796;
  border-color: #858796;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
}

.btn-outline-success {
  color: #1cc88a;
  border-color: #1cc88a;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #1cc88a;
  border-color: #1cc88a;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #1cc88a;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #1cc88a;
  border-color: #1cc88a;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.5);
}

.btn-outline-info {
  color: #36b9cc;
  border-color: #36b9cc;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #36b9cc;
  border-color: #36b9cc;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 185, 204, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #36b9cc;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #36b9cc;
  border-color: #36b9cc;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 185, 204, 0.5);
}

.btn-outline-warning {
  color: #f6c23e;
  border-color: #f6c23e;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #f6c23e;
  border-color: #f6c23e;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 194, 62, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #f6c23e;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f6c23e;
  border-color: #f6c23e;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 194, 62, 0.5);
}

.btn-outline-danger {
  color: #e74a3b;
  border-color: #e74a3b;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #e74a3b;
  border-color: #e74a3b;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #e74a3b;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e74a3b;
  border-color: #e74a3b;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.5);
}

.btn-outline-light {
  color: #f8f9fc;
  border-color: #f8f9fc;
}

.btn-outline-light:hover {
  color: #3a3b45;
  background-color: #f8f9fc;
  border-color: #f8f9fc;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fc;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #3a3b45;
  background-color: #f8f9fc;
  border-color: #f8f9fc;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
}

.btn-outline-dark {
  color: #5a5c69;
  border-color: #5a5c69;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #5a5c69;
  border-color: #5a5c69;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 92, 105, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #5a5c69;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #5a5c69;
  border-color: #5a5c69;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 92, 105, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #4e73df;
  text-decoration: none;
}

.btn-link:hover {
  color: #224abe;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #858796;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
